import { KeyboardEvent, ReactNode, useContext } from "react";
import classnames from "classnames";
import { Container, Label, Input, FormGroup } from "reactstrap";
import { Form } from "react-bootstrap";
import { UserRole } from "../../../models";
import { ConnectionContext } from "./ConnectionContext";
import { ConnectionType } from "../../../models/Connections/ConnectionType";
import { inputUpdated } from "./ConnectionFunctions";
import { VerbosityHelperText } from "./VerbosityHelperText";
import { OracleOciDataSourceHelperText } from "./OracleOciDataSourceHelperText";
import { compareStrings } from "../../../utility/CompareStrings";
import { isUrlValid } from "./isUrlValid";
import { IHierarchyPropertyExtended } from "../../../bffmodels/IHierarchyPropertyExtended";
import DynamicConnectionProperty from "./DynamicConnectionProperty";
import { useAppSelector } from "../../../redux/hooks";

interface IConnectionPropertyProps {
  key: string;
  location: string;
  property: IHierarchyPropertyExtended;
  tab: string;
  hidden?: boolean;
  readOnly?: boolean;
}

export function ConnectionProperty(props: IConnectionPropertyProps) {
  let propertyClass = "";

  const connectionContext = useContext(ConnectionContext);
  const currentUserRole = useAppSelector((state) => state.user.role);
  const property = props.property;
  const displayPropertyName = property.alias ? property.alias : property.name;
  const isBooleanType: boolean = compareStrings(property.type, "Boolean");
  // Helper text is always at least an empty span to avoid crashing the client
  // when a user has Google Translate turned on in Chrome.
  let helperText: ReactNode = <span />;

  const isFieldDisabled = (propertyName: string): boolean => {
    if (compareStrings(props.location, "InitialSetup")) {
      return false;
    }

    if (props.readOnly === true) return true;

    return (
      connectionContext.connectionInfo.existingConnectionType ===
        ConnectionType.UserDefined &&
      currentUserRole === UserRole.Query &&
      !connectionContext.driverInfo.userCredentialPropertyNames.includes(
        propertyName,
      )
    );
  };

  const displayPropertyControl = () => {
    if (dynamicPropertyShouldRender()) {
      return <DynamicConnectionProperty property={props.property} />;
    }

    setDefaultHelperText();
    setInvalidHelperText();

    if (compareStrings(property.type, "String")) {
      return formatStringProperty();
    } else if (
      compareStrings(property.type, "Integer") ||
      compareStrings(property.type, "Long")
    ) {
      return formatNumberProperty();
    } else if (isBooleanType) {
      return formatBooleanProperty();
    }
  };

  const dynamicPropertyShouldRender = () => {
    // If the driver has multiple auth scheme options, we only want to render the dynamic dropdown for standard OAuth schemes.
    let oauthOptionIsSelected = false;

    const driverAuthSchemeOption = connectionContext.driverInfo.basicProps.find(
      (prop) => prop.name === "Auth Scheme",
    );

    if (driverAuthSchemeOption) {
      oauthOptionIsSelected = driverAuthSchemeOption.currentValue === "OAuth";
    }

    return (
      property.valuesQuery &&
      ((driverAuthSchemeOption && oauthOptionIsSelected) ||
        !driverAuthSchemeOption)
    );
  };

  const setDefaultHelperText = () => {
    if (compareStrings(property.propertyName, "Verbosity")) {
      helperText = <VerbosityHelperText />;
    } else if (
      compareStrings(property.propertyName, "DataSource") &&
      compareStrings(property.alias, "Connection String")
    ) {
      property.etc += "MULTILINE";
      helperText = <OracleOciDataSourceHelperText />;
    } else if (
      compareStrings(property.propertyName, "User") &&
      compareStrings(connectionContext.driverType, "SAPSuccessFactors")
    ) {
      helperText = (
        <div className="helper-text">User field is case sensitive.</div>
      );
    } else if (property.helperText) {
      const helperTextClasses = classnames({
        "custom-helper-text": isBooleanType,
        "helper-text mt-1": !isBooleanType,
      });

      helperText = (
        <div className={helperTextClasses} data-testid="helperTextTestId">
          <span>{property.helperText}</span>
        </div>
      );
    }
  };

  const setInvalidHelperText = () => {
    if (!connectionContext.saveAndTestFailed) return;

    // Set invalid text for required properties that are empty
    if (
      compareStrings(property.display, "RequiredBasic") &&
      !property.currentValue
    ) {
      propertyClass = "red-focus";
      helperText = (
        <div className="red-error" data-testid="validation-text">
          {/* 
              The weird spans here are to stop google translate from crashing the page when
              the error message is replace with the default helper text. E.x. the API Key field in MailChimp
            */}
          <span>{displayPropertyName}</span>
          <span> is a required field.</span>
        </div>
      );
      return;
    }

    // Set invalid text for URLs that are formatted incorrectly
    if (
      property.propertyName!.toLowerCase().includes("url") &&
      !property.propertyName!.toLowerCase().includes("params") &&
      !property.propertyName!.toLowerCase().includes("useidurl") &&
      property.currentValue !== "" &&
      !isUrlValid(property.currentValue)
    ) {
      propertyClass = "red-focus";
      helperText = (
        <div className="red-error" data-testid="validation-text">
          <span>Your URL must include &quot;https://&quot;.</span>
        </div>
      );
    }
  };

  const createSelectorField = (options: JSX.Element[] | JSX.Element) => {
    const isDisabledField = isFieldDisabled(property.propertyName!);
    return (
      <div className="mb-3">
        <Form.Control
          data-testid={"select-generic-dropdown"}
          name={property.propertyName}
          as="select"
          className={classnames(
            "form-select",
            "select",
            isDisabledField ? "disabled-form-select" : "",
          )}
          readOnly={isDisabledField}
          value={
            property!.enum!.includes(property.currentValue)
              ? property.currentValue
              : property.enum![0]
          }
          onChange={(event) => inputUpdated(event, connectionContext)}
          onKeyDown={(event: KeyboardEvent<HTMLSelectElement>) => {
            if (isDisabledField) {
              event.preventDefault();
              event.stopPropagation();
            }
          }}
          autoComplete="new-password"
        >
          {options}
        </Form.Control>
        {helperText}
      </div>
    );
  };

  const formatStringProperty = () => {
    if (property.enum && property.enum.length !== 0) {
      const options = mapEnumOptions();
      return createSelectorField(options);
    } else if (
      property.etc?.includes("MULTILINE") ||
      property.etc?.includes("FILESELECTOR")
    ) {
      return (
        <div className="mb-3">
          <Form.Control
            data-testid="multi-line-text-box"
            name={property.propertyName}
            as="textarea"
            placeholder={property.placeholder}
            value={property.currentValue}
            rows={3}
            className={classnames(propertyClass, "text-field")}
            readOnly={isFieldDisabled(property.propertyName!)}
            onChange={(event) => inputUpdated(event, connectionContext)}
            autoComplete="new-password"
          />
          {helperText}
        </div>
      );
    } else {
      if (
        props.tab === "basic" &&
        props.location !== "InitialSetup" &&
        connectionContext.connectionInfo.existingConnectionType ===
          ConnectionType.Shared &&
        connectionContext.connectionInfo.selectedConnectionType ===
          ConnectionType.UserDefined &&
        property.cdataUserCredential
      ) {
        // If this is the first-time user changing the connection type from Shared to UDC,
        // just keep the user-defined properties outlined in blue. In all other cases, this
        // is a user who is coming in to fill in the UDC properties for an existing UDC
        // connection, and we just want to conditionally outline with red if there's not a
        // value in the property field.
        if (!property.currentValue) {
          propertyClass = "red-focus";
          helperText = (
            <div className="red-error" data-testid="validation-text">
              Enter your {connectionContext.driverType} account{" "}
              {displayPropertyName}
            </div>
          );
        } else {
          propertyClass = "blue-focus";
          helperText = <span />;
        }
      }

      return (
        <div className="mb-3">
          <Form.Control
            data-testid="input-generic-text-box"
            name={property.propertyName}
            type={
              compareStrings(property.sensitivity, "PASSWORD") ||
              compareStrings(property.sensitivity, "SENSITIVE")
                ? "password"
                : "text"
            }
            placeholder={property.placeholder}
            value={property.currentValue}
            onChange={(event) => inputUpdated(event, connectionContext)}
            className={classnames(propertyClass, "text-field")}
            readOnly={isFieldDisabled(property.propertyName!)}
            autoComplete="new-password"
          />
          {helperText}
        </div>
      );
    }
  };

  const mapEnumOptions = () => {
    return property.enum!.map((value, index) => (
      <option data-testid={`option-${value}`} key={index} value={value}>
        {value}
      </option>
    ));
  };

  const formatNumberProperty = () => {
    return (
      <div className="mb-3">
        <Form.Control
          data-testid="input-number-text-box"
          name={property.propertyName}
          type="number"
          placeholder={property.placeholder}
          value={property.currentValue}
          className={classnames(propertyClass, "text-field")}
          readOnly={isFieldDisabled(property.propertyName!)}
          onChange={(event) => inputUpdated(event, connectionContext)}
          autoComplete="new-password"
        />
        {helperText}
      </div>
    );
  };

  const formatBooleanProperty = () => {
    const isChecked =
      property.currentValue === "true" ||
      compareStrings(property.currentValue, "true") ||
      compareStrings(property.currentValue, "on");

    //The second input fixes the bug https://cdatajira.atlassian.net/browse/CLOUD-10615
    return (
      <div className="mb-3 toggle-btn">
        <FormGroup switch className="form-check form-switch custom-form">
          <Input
            data-testid="input-toggle-switch"
            type="switch"
            role="switch"
            name={property.propertyName}
            id={property.propertyName + "-switch"}
            value={isChecked ? "true" : "false"}
            checked={isChecked}
            disabled={isFieldDisabled(property.propertyName!)}
            onChange={(event) => inputUpdated(event, connectionContext, true)}
          ></Input>
          <Input
            style={{ display: "none" }}
            type="text"
            name={property.propertyName}
            id={property.propertyName}
            value={isChecked ? "true" : "false"}
            onChange={() => {
              // no-op, stop a react console error
            }}
          ></Input>
        </FormGroup>
      </div>
    );
  };

  return (
    <Container
      data-testid={`property-${property.propertyName}`}
      fluid
      className={classnames(
        "pages-connections-components-ConnectionProperty p-0",
      )}
      hidden={props.hidden || property.enum?.length === 1}
    >
      <div className={classnames({ "bool-property": isBooleanType })}>
        <Label
          data-testid="property-label"
          className={classnames({
            required:
              compareStrings(props.tab, "basic") &&
              (property.cdataUserCredential ||
                compareStrings(property.display, "requiredbasic")),
          })}
          for={property.propertyName}
        >
          {displayPropertyName}
        </Label>
        {displayPropertyControl()}
      </div>
      {/* The empty span here is to prevent Google Translate from crashing the client. */}
      {isBooleanType ? helperText : <span> </span>}
    </Container>
  );
}
