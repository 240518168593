/* eslint-disable max-lines */
import React, { useContext, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "reactstrap";
import { ModalContext } from "../routes/ModalContext";
import { IModalProps } from "../components/CDataModal";
import { RequestType } from "../components/withAPI";
import { useAPI } from "../components/useAPI";
import { ConnectionType, IConnectionPermission, UserRole } from "../models";
import { ToastrError, ToastrSuccess } from "../services/toastrService";
import { doAppOAuth } from "../pages/connections/components/oauth/";
import { shouldSaveOAuthProps } from "../pages/connections/components";
import { IConnectionContext } from "../pages/connections/components/ConnectionContext";
import {
  InitialSetupContext,
  ISelectedConnection,
} from "../pages/initialSetup/Setup/InitialSetupContext";
import { PermissionNames } from "../pages/connections/components/PermissionsCard";
import { addCacheConnection } from "../redux/actions";
import { useUserInfo } from "./useUserInfo";
import { isOAuthAppDriver } from "../pages/connections/components/oauth/isOAuthAppDriver";
import { isOAuthWeb } from "../pages/connections/components/oauth/isOAuthWeb";
import { isUrlValid } from "../pages/connections/components/isUrlValid";
import { createDeepCopy } from "../utility/CreateDeepCopy";
import { useAppDispatch } from "../redux/hooks";
import { IHierarchyPropertyExtended } from "../bffmodels/IHierarchyPropertyExtended";
import { compareStrings } from "../utility/CompareStrings";
import { useIsSpreadsheetUser } from "./useIsSpreadsheetUser";
import { getSalesEmailAddress } from "../utility/LocalizedEmailAddresses";

export type ConnectionArgs = {
  Name?: string;
  ConnectionId?: string;
  ConnectionType?: ConnectionType;
  Driver?: string;
  DriverVersion?: string;
  IsCacheConnection: boolean;
  OAuthProps?: any;
  Props?: ConnectionPropertiesAndValues;
  Permissions?: IConnectionPermission[];
  UserDefinedProps?: ConnectionPropertiesAndValues;
  UserId?: any;
  WalletFileContent?: any;
};

type ConnectionPropertiesAndValues = {
  [k: string]: FormDataEntryValue;
};

export type ConnectionFormValues = {
  userPropValues: ConnectionPropertiesAndValues;
  values: ConnectionPropertiesAndValues;
};

export interface ConnectionCrudCalls {
  compileFormValues: (
    basicDriverProperties: JSX.Element[],
    skipValidation?: boolean,
  ) => ConnectionFormValues | null;
  createConnectionRequest: (
    formValues: ConnectionFormValues,
  ) => Promise<ConnectionArgs | null>;
  openDeleteConnectionModal: (event: React.MouseEvent) => void;
  openSaveConnectionWithCacheJobsModal: (
    event: React.MouseEvent,
    displayedBasicDriverProperties: JSX.Element[],
  ) => void;
  // TODO: Re-enable me in CLOUD-11995 once fingerprint fetching logic is in place
  // openSaveSSHConnectionModal: (
  //   event: React.MouseEvent,
  //   displayedBasicDriverProperties: JSX.Element[],
  // ) => void;
  handleConnectionSubmit: (
    event: React.FormEvent,
    basicDriverProperties: JSX.Element[],
  ) => void;
  handleOauth: (data: ConnectionArgs) => Promise<ConnectionArgs | null>;
}

export function useConnectionCrudCalls(
  connectionContext: IConnectionContext,
  updatePreventNavigation: (
    preventNavigation: boolean,
    preventNavigationTitle: string,
    preventNavigationMessage: string,
  ) => void,
  nextStep?: () => void,
): ConnectionCrudCalls {
  const connectionSaveAttempts = useRef<number | null>(0);
  const modalContext = useContext(ModalContext);
  const api = useAPI();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const userInfo = useUserInfo();
  const isAdmin = userInfo.IsInRole(UserRole.Admin);
  const [searchParams] = useSearchParams();
  const isSpreadsheetUser = useIsSpreadsheetUser();

  const initialSetupContext = useContext(InitialSetupContext);
  const isUdc =
    connectionContext.connectionInfo.selectedConnectionType ===
    ConnectionType.UserDefined;

  const openDeleteConnectionModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    let message =
      "By processing this request, your previously setup Virtual Datasets, Derived Views, Jobs and Saved Queries may become affected that originate from this connection. Are you sure you want to proceed?";

    if (isSpreadsheetUser) {
      message =
        "You are about to delete a connection which can not be undone. Are you sure you want to proceed?";
    }

    const confirmButton = (
      <Button color="danger" onClick={() => onDeleteConnection()}>
        <div className="icon no-pointer-event" />
        Delete
      </Button>
    );
    const modal = {
      title: "Delete Connection",
      body: <div>{message}</div>,
      primaryButton: confirmButton,
      secondaryButton: (
        <Button color="secondary" onClick={modalContext.toggleModal}>
          Close
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
    } as IModalProps;
    modalContext.setModal(modal);
  };

  const openSaveConnectionWithCacheJobsModal = (
    event: React.MouseEvent,
    displayedBasicDriverProperties: JSX.Element[],
  ) => {
    const message =
      "We've found cached jobs linked to this connection. Saving changes will clear the cached data which will restart the job run.";
    const confirmButton = (
      <Button
        id={connectionContext.chameleonId}
        color="primary"
        onClick={() => {
          handleConnectionSubmit(event, displayedBasicDriverProperties);
          modalContext.toggleModal();
        }}
      >
        <div className="icon no-pointer-event" />
        Save & Clear Cache
      </Button>
    );

    const modal = {
      title: "Cached Jobs Detected",
      body: <div>{message}</div>,
      primaryButton: confirmButton,
      secondaryButton: (
        <Button color="secondary" onClick={modalContext.toggleModal}>
          Cancel
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
    } as IModalProps;
    modalContext.setModal(modal);
  };

  // TODO: Re-enable me in CLOUD-11995 once fingerprint fetching logic is in place
  // const openSaveSSHConnectionModal = (
  //   event: React.MouseEvent,
  //   displayedBasicDriverProperties: JSX.Element[],
  // ) => {
  //   const message = (
  //     <>
  //       <CDataTypography>
  //         Connect Cloud has fetched the SSH Server Certificate but couldn&apos;t
  //         verify it. Please review and confirm the certificate below to complete
  //         the connection authentication.
  //       </CDataTypography>
  //       <Box
  //         sx={{
  //           display: "flex",
  //           flexDirection: "row",
  //           gap: "1rem",
  //           marginTop: "10px",
  //         }}
  //       >
  //         <Box sx={{ alignContent: "center" }}>
  //           <CDataTypography variant="typography-variant-body-medium">
  //             SSH Certificate Fingerprint:
  //           </CDataTypography>
  //         </Box>
  //         <Box sx={{ alignContent: "center", flexGrow: "1" }}>
  //           <Input disabled value={"12:34:56:78:90:1"} />
  //         </Box>
  //       </Box>
  //     </>
  //   );
  //   const confirmButton = (
  //     <Button
  //       id={connectionContext.chameleonId}
  //       color="primary"
  //       onClick={() => {
  //         handleConnectionSubmit(
  //           event,
  //           displayedBasicDriverProperties,
  //         );
  //         modalContext.toggleModal();
  //       }}
  //     >
  //       <div className="icon no-pointer-event" />
  //       Confirm
  //     </Button>
  //   );

  //   const modal = {
  //     title: "SSH Server Certificate",
  //     body: <div>{message}</div>,
  //     primaryButton: confirmButton,
  //     secondaryButton: (
  //       <Button color="secondary" onClick={modalContext.toggleModal}>
  //         Cancel
  //       </Button>
  //     ),
  //     displayToggleCloseButton: true,
  //     displayed: true,
  //   } as IModalProps;
  //   modalContext.setModal(modal);
  // };

  const onDeleteConnection = async () => {
    connectionContext.setProcessingRequest(true);
    connectionContext.setUnsavedChanges(false);
    const { status } = await api.callAPI(
      RequestType.Delete,
      `/account/connections/${connectionContext.connectionId}`,
      "Failed to delete connection due to the following error:",
    );
    if (status === 200) {
      modalContext.toggleModal();
      navigate("/connections");
      ToastrSuccess(
        "Connection successfully deleted",
        `Your ${connectionContext.driverType} connection, ${connectionContext.connectionInfo.existingConnectionName} was successfully deleted.`,
      );
    }
    connectionContext.setProcessingRequest(true);
  };

  const handleConnectionSubmit = async (
    event: React.FormEvent,
    basicDriverProperties: JSX.Element[],
  ) => {
    event.preventDefault();

    const formValues = compileFormValues(basicDriverProperties);
    // If we can't get valid values and/or userPropValues, stop the process. Empty userPropValues are considered valid, null ones are not.
    if (
      formValues == null ||
      formValues.values == null ||
      formValues.userPropValues == null
    ) {
      return;
    }

    connectionContext.setUnsavedChanges(false);
    connectionContext.setProcessingRequest(true);

    const data = await createConnectionRequest(formValues);

    // If this is an Oauth driver, try to perform OAuth now. If this returns null, the oauth failed, so we should not save & test.
    const dataWithOauth = await handleOauth(data);
    if (dataWithOauth != null) {
      await saveAndTestConnection(dataWithOauth);
    }

    connectionContext.setProcessingRequest(false);
  };

  /** Extracts the values of the submitted connection form into a format that can be processed more easily.
   * @param basicDriverProperties The array of basic driver properties as JSX elements
   * @param skipValidation Whether field, URL, and sign in validation should be skipped; defaults to false, and should rarely be true.
   *
   * @returns A ConnectionFormValues object that contains the connection values and, if applicable, user defined connection values.
   */
  const compileFormValues = (
    basicDriverProperties: JSX.Element[],
    skipValidation = false,
  ): ConnectionFormValues | null => {
    const values = getFormValues();
    const requiredBasicProperties = getRequiredBasicPropertyNames(
      basicDriverProperties,
    );
    if (
      !skipValidation &&
      (hasFieldErrors(values, requiredBasicProperties) || isOauthIncomplete())
    ) {
      return null;
    }

    const valuesWithoutPermissionNames =
      removePermissionNamesFromValues(values);

    //TODO: Remove this Hardcoded check for SharePoint driver once the driver bug is fixed (DRIVERS-37673).
    const propertiesNotSetToDefaultValue =
      connectionContext.driverInfo?.name === "SharePoint"
        ? valuesWithoutPermissionNames
        : removedPropertiesWithDefaultValues(valuesWithoutPermissionNames);

    const valuesWithUserDefinedValues = extractUserDefinedProperties(
      propertiesNotSetToDefaultValue,
    );

    return valuesWithUserDefinedValues;
  };

  const getFormValues = (): ConnectionPropertiesAndValues => {
    const formData = new FormData(connectionContext.formRef.current!);
    return Object.fromEntries(formData.entries());
  };

  const getRequiredBasicPropertyNames = (
    basicDriverProperties: JSX.Element[],
  ): string[] => {
    const requiredProperties = basicDriverProperties.filter(
      (property) => property.props.property?.display === "RequiredBasic",
    );
    return requiredProperties.map((property) => property.key as string);
  };

  /** Checks for required fields that are incomplete and URLs that are formatted incorrectly */
  const hasFieldErrors = (
    values: ConnectionPropertiesAndValues,
    requiredBasicProperties: string[],
  ) => {
    /** Check if any fields in requiredBasicProperties are empty */
    const hasEmptyRequiredFields = requiredBasicProperties.some(
      (key) => values[key] === "",
    );

    /** Check if the user has attempted to save a name that's already taken */
    const nameIsTaken = connectionContext.unavailableNames.includes(
      values.connectionName?.toString().toLocaleLowerCase(),
    );

    /** Check if any URL fields that contain values are not formatted correctly */
    const hasInvalidUrls = Object.keys(values).some(
      (key) =>
        key.toLowerCase().includes("url") &&
        !key.toLowerCase().includes("params") &&
        !key.toLowerCase().includes("useidurl") &&
        values[key] &&
        !isUrlValid(values[key].toString()),
    );

    if (hasEmptyRequiredFields || hasInvalidUrls || nameIsTaken) {
      connectionContext.setSaveAndTestFailed(true);
      ToastrError(
        "There was an error with your request",
        "Please correct the issues with the fields outlined in red below.",
      );
      return true;
    }
    return false;
  };

  /** If the Sign In button is present and the user hasn't signed in, displays an error message on the sign in button. */
  const isOauthIncomplete = () => {
    if (
      isOAuthWeb(connectionContext.driverInfo!) &&
      !connectionContext.connectionInfo?.isOauthTokenPresent
    ) {
      connectionContext.setOauthButtonInvalid?.(true);
      ToastrError(
        "Test Connection Failed!",
        `Click the Sign-In button below first in order to authenticate to ${connectionContext.driverInfo?.name}.`,
      );
      return true;
    }
    return false;
  };

  function getPropertiesListRecursively(
    propertiesList: IHierarchyPropertyExtended[],
  ) {
    let allPropertiesList: IHierarchyPropertyExtended[] = [];

    for (const property of propertiesList) {
      allPropertiesList.push(property);

      const hierarchyRules = property.hierarchyRules;

      if (hierarchyRules == null) {
        continue;
      }

      for (const key of Object.keys(hierarchyRules)) {
        if (!compareStrings(property.currentValue, key)) {
          continue;
        }
        const hierarchyPropertiesList = getPropertiesListRecursively(
          hierarchyRules[key],
        );
        allPropertiesList = [...allPropertiesList, ...hierarchyPropertiesList];
      }
    }

    return allPropertiesList;
  }

  /** Removing properties that are going with default values */
  const removedPropertiesWithDefaultValues = (
    values: ConnectionPropertiesAndValues,
  ) => {
    const defaultValuesMap: { [key: string]: string } = {};

    const allBasicProperties: IHierarchyPropertyExtended[] =
      getPropertiesListRecursively(connectionContext.driverInfo.basicProps);
    let allAdvancedProperties: IHierarchyPropertyExtended[] = [];
    for (const advancedProp of connectionContext.driverInfo.advancedProps) {
      if (advancedProp.properties != null)
        allAdvancedProperties = [
          ...allAdvancedProperties,
          ...getPropertiesListRecursively(advancedProp.properties),
        ];
    }

    [...allBasicProperties, ...allAdvancedProperties].forEach((prop) => {
      if (prop.defaultDriverValue != null && prop.propertyName != null) {
        defaultValuesMap[prop.propertyName.toLowerCase()] =
          prop.type === "Boolean"
            ? prop.defaultDriverValue?.toLowerCase()
            : prop.defaultDriverValue;
      }
    });

    Object.keys(values).forEach((key) => {
      // TODO: Saving AuthScheme for all connections to address the NetSuite driver issue (CLOUD-12914).
      // Revert this change after updating to the V24 driver (CLOUD-12763) and validating the fix.
      if (
        defaultValuesMap[key.toLowerCase()] === values[key] &&
        key.toLowerCase() !== "authscheme"
      ) {
        delete values[key];
      }
    });

    return values;
  };

  /** Discard raw permission names in the values to avoid errors downstream */
  const removePermissionNamesFromValues = (
    values: ConnectionPropertiesAndValues,
  ) => {
    const cleanedValues = createDeepCopy(values);

    Object.values(PermissionNames).forEach((permission) => {
      delete cleanedValues[permission];
    });

    return cleanedValues;
  };

  /** Finds connection properties that are indicated as user defined props. For these properties, removes
   *  the value from the general values object and adds them to the user defined props object instead. */
  const extractUserDefinedProperties = (
    values: ConnectionPropertiesAndValues,
  ): ConnectionFormValues => {
    const valuesWithoutUserDefined = createDeepCopy(values);
    const userDefinedProps = connectionContext.userDefinedProps;
    const userPropValues: ConnectionPropertiesAndValues = {};

    Object.keys(valuesWithoutUserDefined).forEach((key) => {
      if (userDefinedProps.includes(key)) {
        userPropValues[key] = valuesWithoutUserDefined[key];
        if (isUdc) {
          delete valuesWithoutUserDefined[key];
        }
      }
    });

    return {
      values: valuesWithoutUserDefined,
      userPropValues: userPropValues,
    };
  };

  /** Turns the extracted field values into a connection request object for sending to the back-end. */
  const createConnectionRequest = async (
    formValues: ConnectionFormValues,
  ): Promise<ConnectionArgs> => {
    const { values, userPropValues } = formValues;

    const data: ConnectionArgs = {
      ConnectionId: connectionContext.connectionId,
      ConnectionType: connectionContext.isInitialSetupPage
        ? ConnectionType.Shared
        : connectionContext.connectionInfo.selectedConnectionType,

      Driver: connectionContext.isInitialSetupPage
        ? initialSetupContext.selectedConnection.driver
        : connectionContext.driverType,

      DriverVersion: connectionContext.driverInfo.version,
      IsCacheConnection: connectionContext.isCacheConnection,
      Name: values.connectionName?.toString(),
      OAuthProps: {},
      Permissions: mapConnectionPermissions(),
      UserId: userInfo.Self.id,
      WalletFileContent: connectionContext.selectedFile,
    };

    // Add user-defined props to the connection if this is a non-"sign-in" user-defined connection
    if (userPropValues && !isOAuthWeb(connectionContext.driverInfo) && isUdc) {
      data.UserDefinedProps = userPropValues;
    }

    // Delete the connectionName property since the back-end doesn't like it
    delete values.connectionName;
    data.Props = values;

    return data;
  };

  const mapConnectionPermissions = (): IConnectionPermission[] | undefined => {
    if (connectionContext.isInitialSetupPage) {
      return [
        {
          userId: userInfo.Self.id,
          opsAllowed: 31,
        },
      ];
    } else {
      // If we're on the edit connection page, and the user is an admin (since query users cannot modify permissions),
      // map the new permissions to the data
      if (isAdmin) {
        return connectionContext.permissions?.map((permission) => ({
          userId: permission.id,
          opsAllowed: permission.opsAllowed,
        }));
      }
    }
  };

  /** Performs OAuth actions for the connection if it is an OAuth driver. The actions taken depend on whether the driver is of type OAuthApp or OAuthWeb.
   *
   * @returns The connection data with OAuth credentials if OAuth succeeds
   * @returns Null if OAuth fails
   * @returns The unmodified data object if the connection is not OAuth
   */
  const handleOauth = async (data: ConnectionArgs) => {
    if (isOAuthAppDriver(connectionContext.driverInfo)) {
      return await performAppOauth(data);
    }

    if (isOAuthWeb(connectionContext.driverInfo)) {
      return addWebOauthProps(data);
    }

    return data;
  };

  const performAppOauth = async (
    data: ConnectionArgs,
  ): Promise<ConnectionArgs | null> => {
    const appOAuthProps: ConnectionPropertiesAndValues | null =
      await doAppOAuth(connectionContext, modalContext, userInfo.Self.id, api);

    if (!appOAuthProps) {
      incrementSaveFailure();
      return null;
    }

    return mapSharedPropsForAppOauth(appOAuthProps, data);
  };

  const incrementSaveFailure = () => {
    connectionSaveAttempts.current = (connectionSaveAttempts.current ?? 0) + 1;

    if (connectionSaveAttempts.current === 3) {
      connectionContext.setChameleonId("chameleon-failed");
    }
  };

  /** Overrides all props in target with values from the source. */
  const overrideProps = (
    target: ConnectionPropertiesAndValues,
    source: ConnectionPropertiesAndValues,
  ) => {
    Object.keys(source).forEach((prop: any) => {
      target[prop] = source[prop];
    });
  };

  const mapSharedPropsForAppOauth = (
    appOAuthProps: ConnectionPropertiesAndValues,
    data: ConnectionArgs,
  ): ConnectionArgs => {
    const dataWithOauth = createDeepCopy(data);

    overrideProps(dataWithOauth.OAuthProps, appOAuthProps);
    dataWithOauth.OAuthProps["OAuthExpiresIn"] = "1";

    return dataWithOauth;
  };

  const addWebOauthProps = (data: ConnectionArgs): ConnectionArgs => {
    if (shouldSaveOAuthProps(connectionContext)) {
      if (isUdc) {
        return mapUserDefinedPropsForWebOauth(data);
      } else {
        return mapSharedPropsForWebOauth(data);
      }
    } else {
      return mapSharedOauthWithExpiration(data);
    }
  };

  const mapUserDefinedPropsForWebOauth = (
    data: ConnectionArgs,
  ): ConnectionArgs => {
    const dataWithOauth = createDeepCopy(data);
    if (dataWithOauth.UserDefinedProps == null) {
      dataWithOauth.UserDefinedProps = {};
    }
    overrideProps(
      dataWithOauth.UserDefinedProps!,
      connectionContext.oauthProps!,
    );
    return dataWithOauth;
  };

  const mapSharedPropsForWebOauth = (data: ConnectionArgs): ConnectionArgs => {
    const dataWithOauth = createDeepCopy(data);
    overrideProps(dataWithOauth.Props!, connectionContext.oauthProps!);
    return dataWithOauth;
  };

  const mapSharedOauthWithExpiration = (
    data: ConnectionArgs,
  ): ConnectionArgs => {
    const dataWithOauth = createDeepCopy(data);

    overrideProps(dataWithOauth.OAuthProps, connectionContext.oauthProps!);
    dataWithOauth.OAuthProps["OAuthExpiresIn"] = "1";

    return dataWithOauth;
  };

  const saveAndTestConnection = async (data: ConnectionArgs) => {
    const { requestPath, requestType, route } = getRequestParameters();

    const { status, payload, error } = await api.callAPI(
      requestType,
      route,
      "",
      data,
    );

    if (status === 200) {
      handleSuccessfulCreation(data, payload, requestPath);
    } else {
      handleFailedCreation(error);
    }
  };

  const getRequestParameters = (): {
    requestPath: string;
    requestType: RequestType;
    route: string;
  } => {
    const { requestPath, requestType } = getRequestPathAndType();
    const route = getRequestRoute(requestPath);

    return {
      requestPath: requestPath,
      requestType: requestType,
      route: route,
    };
  };

  const getRequestPathAndType = (): {
    requestPath: string;
    requestType: RequestType;
  } => {
    let requestPath: string, requestType: RequestType;
    if (!connectionContext.connectionId) {
      requestPath = "connections";
      requestType = RequestType.Post;
    } else {
      requestType = RequestType.Put;
      if (
        connectionContext.connectionInfo.selectedConnectionType ===
        ConnectionType.Shared
      ) {
        requestPath = "updateConnection";
      } else {
        requestPath = isAdmin ? "updateConnection" : "updateUserConnection";
      }
    }

    return { requestPath, requestType };
  };

  const getRequestRoute = (requestPath: string): string => {
    if (connectionContext.isCacheConnection) {
      return "/cacheJobs/database";
    } else if (connectionContext.isOEMConnection) {
      return "/oem/user/connection";
    } else {
      return `/account/${requestPath}`;
    }
  };

  const handleSuccessfulCreation = (
    data: ConnectionArgs,
    payload: any,
    requestPath: string,
  ) => {
    if (payload) {
      processPayload(payload);

      // Connections can sometimes create successfully but fail testing. This handles those scenarios.
      if (payload.testConnectionError) {
        handleFailedTest(payload.testConnectionError);
        return;
      }
    }

    const successMessage = getSuccessMessage(data.Name!, requestPath);
    ToastrSuccess("Connection successfully saved", successMessage);
    updateSuccessfulContext(data.Name!);

    if (connectionContext.isInitialSetupPage) {
      handleInitialSetupSuccess(data, payload);
    }
  };

  const processPayload = (payload: any) => {
    let refreshUrl = ".";

    // Perform tasks specific to OEM connections if it is one
    if (connectionContext.isOEMConnection) {
      // We don't want to refresh the page for OEM connections since they navigate away manually
      refreshUrl = `.${window.location.search}`;

      // If an OEM connection saves successfully but the test fails, we need to update the JWT in session storage with the JWT returned in the response object.
      if (payload.jwt) {
        window.sessionStorage.setItem("oemJwt", payload.jwt);
      }
    }

    // We store cache connections in redux; this updates it.
    if (connectionContext.isCacheConnection) {
      dispatch(addCacheConnection(payload.connection));
    }

    // If this is a new connection, handle the tasks to turn it into an edit connection
    if (!connectionContext.connectionId) {
      window.DD_RUM?.addAction(
        "Successfully saved connection, user is now editing an existing connection",
        {
          driver: connectionContext.driverInfo.name,
        },
      );
      connectionContext.setConnectionId(payload.connection.id);

      // Refresh the page state to display it as edit connection
      navigate(refreshUrl, {
        state: {
          ...(location.state as any),
          connectionId: payload.connection.id,
        },
      });
    }
  };

  const handleFailedTest = (testConnectionError: any) => {
    ToastrError(
      "Unable to successfully test your connection",
      testConnectionError.error.message,
      customErrMessage,
    );

    incrementSaveFailure();
    updateFailedContext();
  };

  const getSuccessMessage = (name: string, requestPath: string): string => {
    const successConnectionName = connectionContext.isCacheConnection
      ? "Your Caching connection has been "
      : `Your ${connectionContext.driverType} connection, ${name}, was `;

    const successType = requestPath.includes("update")
      ? "updated successfully."
      : "successfully saved & authenticated.";

    const originatingClient = searchParams.get("originatingClient");
    let returnToClient = "";
    if (originatingClient) {
      returnToClient = ` You may now return to the Connect Cloud ${originatingClient} plugin to begin importing data.`;
    }

    return `${successConnectionName}${successType}${returnToClient}`;
  };

  const updateSuccessfulContext = (name: string) => {
    connectionContext.setChameleonId("open-chameleon-form");
    connectionContext.setConnectionInfo({
      ...connectionContext.connectionInfo,
      existingConnectionName: name,
      isTested: true,
      connectionLastModified: new Date().toUTCString(),
    });
  };

  const updateFailedContext = () => {
    connectionContext.setConnectionInfo({
      ...connectionContext.connectionInfo,
      isTested: false,
      connectionLastModified: new Date().toUTCString(),
    });
  };

  const handleInitialSetupSuccess = (data: ConnectionArgs, payload: any) => {
    updatePreventNavigation(false, "", "");
    const selectedConnection: ISelectedConnection & {
      permissions: ConnectionArgs["Permissions"];
    } = {
      driver: initialSetupContext.selectedConnection.driver,
      niceName: initialSetupContext.selectedConnection.niceName,
      name: data.Name!,
      connectionId: payload ? payload.connection.id : data.ConnectionId, //depending on whether it is create or update
      properties: data.Props!,
      permissions: data.Permissions,
    };

    initialSetupContext.setSelectedConnection(selectedConnection);

    // If the driver supports custom reports, this opens the Custom Reports tab. Else, this moves to the next step in the onboarding wizard.
    if (connectionContext.driverInfo.reportTypes) {
      connectionContext.setCurrentlyCreatingReports(true);
      connectionContext.setCurrentTab("4");
    } else {
      nextStep!();
    }
  };

  const handleFailedCreation = (error: any) => {
    // If we do not get a message back from the server, use the generic error message.
    // If we do get a message back, use our generic error message as the details text.
    const message = error.message ?? customErrMessage;
    const details = error.message != null ? customErrMessage : "";
    ToastrError(
      "Unable to successfully test your connection",
      message,
      details,
    );

    incrementSaveFailure();
    updateFailedContext();
  };

  const customErrMessage = (
    <div>
      Your changes have been saved, but we could not successfully authenticate
      testing your connection due to a technical issue. Please double check your
      authentication parameters and try testing your connection again. If the
      problem persists, please reach out to{" "}
      <a
        href={`mailto:${getSalesEmailAddress()}`}
        className="support-hyperlink"
      >
        {getSalesEmailAddress()}
      </a>{" "}
      for further assistance.
    </div>
  );

  const modalAndAPIs: ConnectionCrudCalls = {
    compileFormValues: compileFormValues,
    createConnectionRequest: createConnectionRequest,
    openDeleteConnectionModal: openDeleteConnectionModal,
    openSaveConnectionWithCacheJobsModal: openSaveConnectionWithCacheJobsModal,
    // openSaveSSHConnectionModal: openSaveSSHConnectionModal,
    handleConnectionSubmit: handleConnectionSubmit,
    handleOauth: handleOauth,
  };

  return modalAndAPIs;
}
